<template>
  <div>
    <b-row>
      <b-col cols="8">
        <setting-global />
        <history-all />
      </b-col>
      <b-col cols="4">
        <info />
        <payment />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow, } from 'bootstrap-vue'
import SettingGlobal from './components/settingGlobal.vue'
import HistoryAll from './components/HistoryAll.vue'
import Info from './components/Info.vue'
import Payment from './components/Payment.vue'

export default {
  components: { BCol, BRow, SettingGlobal, HistoryAll, Info, Payment },

}
</script>

<style>

</style>