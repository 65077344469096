<template>
  <div>
    <app-collapse>
      <app-collapse-item :isVisible="true" title="Cài Đặt Thanh Toán Trước">
        <table class="table table-bordered">
          <tr>
            <th>Tên Phương Thức Thanh Toán</th>
            <th>Phí</th>
            <th></th>
          </tr>
          <tr v-for="(item, k) in paymentItems" :key="k">
            <td>{{ item.name }}</td>
            <td>{{ item.cost.toLocaleString() }}đ</td>
            <td><b-button variant="warning" size="sm"><feather-icon icon="XIcon" /></b-button></td>
          </tr>
        </table>
        <b-row>
          <b-col>
            <div style="padding-top: 10px; text-align: right">
              <b-button v-b-modal.payment size="sm" variant="success" @click="addPayMent"><feather-icon icon="PlusIcon" /> Thêm</b-button>
            </div>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>
    <b-modal title="Thêm Thanh Toán" size="lg" id="payment">
      <b-form-group label="Thêm Thanh Toán" label-cols="3">
        <b-form-input />
      </b-form-group>
      <b-form-group label="Phí" label-cols="3">
        <b-form-input type="number"/>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import { BCol, BRow, BButton, BModal, BFormInput, BFormGroup, } from 'bootstrap-vue'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  data() {
    return {
      paymentItems: [
        {
          id: 1, name: 'Tiền Mặt', cost: 1000,
        },
        {
          id: 2, name: 'Ngân Hàng', cost: 1000,
        },
        {
          id: 3, name: 'Ví Điện Tử', cost: 1000,
        },
      ],
    }
  },
  components: { BFormGroup, BModal, BFormInput, BCol, BRow, BButton, FeatherIcon, AppCollapse, AppCollapseItem },

}
</script>
