<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Cài Đặt Chung
      </b-card-title>
      <b-card-sub-title>
        <b-button size="sm" @click="onSubmit" variant="success">
          <feather-icon icon="PlusIcon" />Cập Nhật
        </b-button>
      </b-card-sub-title>
    </b-card-header>
    <b-card-body>
      <b-form-group label="Tên Website">
        <b-form-input />
      </b-form-group>
      <b-form-group label="Logo Website">
        <b-form-file />
      </b-form-group>
      <b-form-group label="Email">
        <b-form-input />
      </b-form-group>
      <b-form-group label="Số Điện Thoại">
        <b-form-input />
      </b-form-group>
      <b-form-group label="Năm Học">
        <b-form-input />
      </b-form-group>
      <b-form-group label="Địa Chỉ">
        <b-form-input />
      </b-form-group>
    </b-card-body>
  </b-card>
</template>
<script>
import { BFormFile, BFormInput, BFormGroup, BButton, BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardBody } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
export default {
  components: { BFormFile, BFormInput, BFormGroup, BButton, BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardBody, FeatherIcon }
}
</script>

<style>

</style>