<template>
  <app-collapse>
    <app-collapse-item
      :isVisible="true" title="Lịch Sử Hoạt Động"
      headerStyle="background-color: #d2d6de"
    >
      <app-timeline>
        <app-timeline-item
          v-for="(item, k) in items"
          :key="k"
          :title="item.title"
          :subtitle="item.content"
          :time="item.time"
          :variant="item.variant"
        />
      </app-timeline>
    </app-collapse-item>
  </app-collapse>
</template>
<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'

export default {
  props: {
    items: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  components: { AppTimeline, AppTimelineItem, FeatherIcon, AppCollapse, AppCollapseItem }
}
</script>

<style>

</style>