<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Thông Tin Tài Khoản
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <table class="table table-bordered">
        <tr>
          <td>Tên Gói</td>
          <td>Gói Premium</td>
        </tr>
        <tr>
          <td>Thời Hạn</td>
          <td>Vô Hạn</td>
        </tr>
      </table>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardBody } from 'bootstrap-vue'

export default {
  components: { BCard, BCardHeader, BCardTitle, BCardBody },
}
</script>

<style>

</style>